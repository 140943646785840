import React, { useRef } from "react";
import { FiSend } from "react-icons/fi";
import { FaHandPointLeft, FaHandPointRight } from "react-icons/fa";
import { useHandAnimation } from "./useHandAnimation";

interface DesktopPromptInputProps {
  prompt: string;
  placeholderText: string;
  theme: "light" | "dark";
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent) => void;
}

const DesktopPromptInput: React.FC<DesktopPromptInputProps> = ({
  prompt,
  placeholderText,
  theme,
  handleInputChange,
  handleSubmit,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const leftHandRef = useRef<SVGSVGElement>(null);
  const rightHandRef = useRef<SVGSVGElement>(null);

  const { showHands } = useHandAnimation(
    inputRef,
    inputContainerRef,
    leftHandRef,
    rightHandRef,
  );

  return (
    <div
      ref={inputContainerRef}
      className="w-3/4 relative flex items-center transition-all duration-500 ease-out"
    >
      {showHands && (
        <FaHandPointRight
          ref={leftHandRef}
          className={`text-3xl absolute -left-12 ${
            theme === "light" ? "text-black" : "text-white"
          } opacity-0 hand-icon`}
        />
      )}
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholderText}
        value={prompt}
        onChange={handleInputChange}
        className={`w-full px-4 py-3 rounded-full border ${
          theme === "light"
            ? "border-black text-black placeholder-black"
            : "border-white text-white placeholder-white"
        } bg-transparent focus:outline-none focus:ring-2 focus:ring-[#f1f1f4] pr-12 transition-all duration-500 ease-out`}
        autoComplete="new-password"
      />
      {showHands && (
        <FaHandPointLeft
          ref={rightHandRef}
          className={`text-3xl absolute -right-12 ${
            theme === "light" ? "text-black" : "text-white"
          } opacity-0 hand-icon`}
        />
      )}
      <button
        type="submit"
        onClick={handleSubmit}
        className={`absolute right-0 top-0 bottom-0 bg-transparent ${
          theme === "light" ? "text-black" : "text-white"
        } rounded-r-full p-3 hover:bg-opacity-10 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-[#f1f1f4]`}
      >
        <FiSend size={24} />
      </button>
    </div>
  );
};

export default DesktopPromptInput;
