import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import Step from "./Step";
import { steps } from "./stepsData";

const MobileFourSteps: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <div
      className={`py-20 ${theme === "light" ? "bg-white" : "bg-black"} ${theme === "light" ? "text-black" : "text-white"}`}
    >
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">
          {t("FourSteps.title")}
        </h2>
        <div>
          {steps.map((step, index) => (
            <Step key={index} {...step} number={index + 1} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileFourSteps;
