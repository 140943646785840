import React, { useRef, useEffect, useState, useCallback } from 'react';
import Hls from 'hls.js';

const useHlsPlayer = (url: string, addError: (error: string) => void, setPlaybackMethod: (method: string) => void) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<Hls | null>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement || !url) return;

    let hasPlayed = false; // Ensure only one play attempt

    const handleVideoError = () => {
      const error = videoElement?.error;
      if (error) {
        let errorMessage = '';
        switch (error.code) {
          case MediaError.MEDIA_ERR_ABORTED:
            errorMessage = 'Video playback aborted.';
            break;
          case MediaError.MEDIA_ERR_NETWORK:
            errorMessage = 'A network error occurred during video playback.';
            break;
          case MediaError.MEDIA_ERR_DECODE:
            errorMessage = 'An error occurred while decoding the video.';
            break;
          case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
            errorMessage = 'The video format is not supported.';
            break;
          default:
            errorMessage = 'An unknown error occurred during video playback.';
            break;
        }
        addError(`Video element error: ${errorMessage} (Code: ${error.code})`);
      }
    };

    videoElement.addEventListener('error', handleVideoError);

    // Check if browser supports HLS natively
    if (videoElement.canPlayType('application/vnd.apple.mpegurl') || videoElement.canPlayType('application/x-mpegURL')) {
      setPlaybackMethod('Native HLS support');
      videoElement.src = url;

      videoElement.addEventListener('loadedmetadata', () => {
        if (!hasPlayed) {
          videoElement.play().catch(err => addError(`Error during play: ${err.message}`));
          hasPlayed = true; // Prevent multiple play calls
        }
      });
    } else if (Hls.isSupported()) {
      setPlaybackMethod('HLS.js');

      if (!hlsRef.current) {
        const hls = new Hls();
        hlsRef.current = hls;

        hls.loadSource(url);
        hls.attachMedia(videoElement);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (!hasPlayed) {
            videoElement.play().catch(err => addError(`Error during play: ${err.message}`));
            hasPlayed = true; // Only play once
          }
        });

        hls.on(Hls.Events.ERROR, (event, data) => {
          const errorMessage = `HLS error: ${data.type}, Details: ${JSON.stringify(data)}`;
          addError(errorMessage);

          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                addError('Fatal network error encountered, trying to recover...');
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                addError('Fatal media error encountered, trying to recover...');
                hls.recoverMediaError();
                break;
              default:
                addError('Unrecoverable error, destroying HLS instance...');
                hls.destroy();
                break;
            }
          }
        });
      }
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
      videoElement.removeEventListener('error', handleVideoError);
    };
  }, [url, addError, setPlaybackMethod]);

  return videoRef;
};

const CustomVideoPlayer = ({ url }: { url: string }) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [playbackMethod, setPlaybackMethod] = useState<string | null>(null);

  const addError = useCallback((error: string) => {
    setErrors(prevErrors => [...prevErrors, error]);
  }, []);

  const videoRef = useHlsPlayer(url, addError, setPlaybackMethod);

  return (
    <div>
      <video
        ref={videoRef}
        className="w-full"
        controls
        muted
        playsInline
      >
        Your browser does not support the video tag.
      </video>
      
      {/* Display playback method */}
      {playbackMethod && (
        <div className="text-green-500 mt-2">
          <strong>Playback Method: </strong>{playbackMethod}
        </div>
      )}
      
      {/* Display errors */}
      {errors.length > 0 && (
        <div className="text-red-500 mt-2">
          <h4>Errors:</h4>
          <ul>
            {errors.map((error, index) => (
              <li key={index} className="mt-1">{error}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default React.memo(CustomVideoPlayer);
