import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { Website } from "./types";
import { unsortedWebsites } from "./websiteData";
import WebsiteCard from "./WebsiteCard";

interface MobileWebsiteListProps {
  websiteOrder: Website["id"][];
}

const MobileWebsiteList: React.FC<MobileWebsiteListProps> = ({
  websiteOrder,
}) => {
  const websites = unsortedWebsites.sort(
    (a, b) => websiteOrder.indexOf(a.id) - websiteOrder.indexOf(b.id),
  );

  const { theme } = useTheme();

  return (
    <div
      className={`pt-32 pb-20 ${theme === "light" ? "bg-white" : "bg-black"} hidden`}
    >
      <h2
        className={`text-4xl font-bold text-center ${theme === "light" ? "text-black" : "text-white"} mb-12`}
      >
        They chose CreateSite
      </h2>
      <div className="max-w-6xl mx-auto px-4">
        <div className="space-y-6">
          {websites.map((website) => (
            <WebsiteCard key={website.id} website={website} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileWebsiteList;
