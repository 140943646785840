import React, { useRef } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useNavigate } from "react-router-dom";
import { usePromptEmail } from "../../contexts/PromptEmailContext";
import useGrowingImageEffects from "./useGrowingImageEffects";
import useConfettiEffect from "./useConfettiEffect";

const GrowingImage: React.FC = () => {
  const { theme } = useTheme();
  const imageRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const confettiRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { setAutoFocus } = usePromptEmail();

  const { handleMouseEnter, handleMouseLeave, handleMouseMove } =
    useGrowingImageEffects(buttonRef, confettiRef);
  useConfettiEffect(confettiRef);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      navigate("/");
      setAutoFocus(true);
    }, 1000);
  };

  return (
    <div
      className={`relative w-full h-full overflow-hidden flex items-center justify-center px-4 mobile:px-2 tablet:px-3 ${theme === "light" ? "bg-white" : "bg-black"}`}
    >
      <div
        ref={imageRef}
        className={`flex flex-col items-center justify-center w-full h-full mobile:w-[90%] tablet:w-[90%] ${theme === "light" ? "bg-white" : "bg-black"}`}
      >
        <h2
          className={`text-4xl md:text-6xl lg:text-7xl mobile:text-3xl tablet:text-6xl laptop:text-7xl desktop:text-8xl mobile:text-3xl font-bold ${theme === "light" ? "text-black" : "text-white"} text-center mb-6`}
        >
          It's your turn now
        </h2>
        <div className="relative">
          <button
            ref={buttonRef}
            onClick={handleScrollToTop}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            className={`mt-4 mobile:mt-2 mobile:text-sm transition-colors duration-300 font-medium py-2 px-4 rounded-md border ${theme === "light" ? "bg-black text-white hover:bg-white hover:text-black border-black" : "bg-white text-[#030410] hover:bg-transparent hover:text-white border-white"}`}
          >
            Create my website 🥳
          </button>
          <div
            ref={confettiRef}
            className="absolute inset-0 pointer-events-none"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default GrowingImage;
