import React from "react";
import { Link } from "react-router-dom";
import { FaDiscord, FaEnvelope, FaSun, FaMoon } from "react-icons/fa";
import { useTheme } from "../../contexts/ThemeContext";
import CartWrapper from "../CartWrapper";

interface HeaderActionsProps {
  setShowContactModal: (show: boolean) => void;
}

const HeaderActions: React.FC<HeaderActionsProps> = ({
  setShowContactModal,
}) => {
  const { theme, toggleTheme } = useTheme();

  const handleMailIconClick = () => {
    setShowContactModal(true);
  };

  return (
    <div className="flex justify-end items-center mobile:mt-8 tablet:mt-8">
      <Link
        className={`elem10 box-border cursor-pointer inline-block transition-[transform,color] duration-300 ease-in-out text-center justify-center leading-[1.143em] text-[24px] bg-transparent ${
          theme === "light" ? "text-black" : "text-white"
        } font-medium p-2 rounded-full hover:scale-[1.1] mobile:mr-2 tablet:mr-3 laptop:mr-4 desktop:mr-4`}
        to="https://discord.gg/RjXuYbf9cM"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Join Discord"
      >
        <FaDiscord />
      </Link>
      <button
        className={`elem10 box-border cursor-pointer inline-block transition-[transform,color] duration-300 ease-in-out text-center justify-center leading-[1.143em] text-[24px] bg-transparent ${
          theme === "light" ? "text-black" : "text-white"
        } font-medium p-2 rounded-full hover:scale-[1.1] mobile:mr-2 tablet:mr-3 laptop:mr-4 desktop:mr-4`}
        onClick={handleMailIconClick}
        aria-label="Contact Email"
      >
        <FaEnvelope />
      </button>
      <button
        className={`elem10 box-border cursor-pointer inline-block transition-[transform,color] duration-300 ease-in-out text-center justify-center leading-[1.143em] text-[24px] bg-transparent ${
          theme === "light" ? "text-black" : "text-white"
        } font-medium p-2 rounded-full hover:scale-[1.1] mobile:mr-2 tablet:mr-3 laptop:mr-4 desktop:mr-4`}
        onClick={toggleTheme}
        aria-label="Toggle Theme"
      >
        {theme === "light" ? <FaMoon /> : <FaSun />}
      </button>
      <CartWrapper />
    </div>
  );
};

export default HeaderActions;
