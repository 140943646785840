import React from "react";
import { FiExternalLink } from "react-icons/fi";
import { useTheme } from "../../contexts/ThemeContext";
import { Website } from "./types";

interface WebsiteCardProps {
  website: Website;
}

const WebsiteCard: React.FC<WebsiteCardProps> = ({ website }) => {
  const { theme } = useTheme();

  return (
    <a
      href={website.externalUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={`${theme === "light" ? "bg-gray-100" : "bg-black"} rounded-lg overflow-hidden cursor-pointer transform transition-transform duration-300 block group`}
    >
      <div className="relative aspect-w-16 aspect-h-9">
        <img
          src={website.url}
          alt={website.name}
          className="w-full h-full object-cover object-top"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <FiExternalLink className="text-white text-3xl" />
        </div>
      </div>
      <div className="p-4">
        <h3
          className={`text-xl font-semibold ${theme === "light" ? "text-black" : "text-white"} mb-2`}
        >
          {website.name}
        </h3>
        <p
          className={`${theme === "light" ? "text-gray-600" : "text-gray-300"} text-sm`}
        >
          {website.description}
        </p>
      </div>
    </a>
  );
};

export default WebsiteCard;
