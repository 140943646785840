import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { usePromptEmail } from "../../contexts/PromptEmailContext";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import MobilePromptInput from "../MobilePromptInput";
import DesktopPromptInput from "./DesktopPromptInput";
import { useUnifiedPromptInput } from "./useUnifiedPromptInput";

interface UnifiedPromptInputProps {
  setShowEmailModal: (show: boolean) => void;
}

function UnifiedPromptInput({ setShowEmailModal }: UnifiedPromptInputProps) {
  const { prompt, setPrompt } = usePromptEmail();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 767px)");

  const { placeholderText, handleSubmit, handleInputChange } =
    useUnifiedPromptInput(setShowEmailModal);

  const startupDescriptions = [
    t("UnifiedPromptInput.placeholder1"),
    t("UnifiedPromptInput.placeholder2"),
    t("UnifiedPromptInput.placeholder3"),
    t("UnifiedPromptInput.placeholder1"),
    t("UnifiedPromptInput.placeholder2"),
  ];

  return (
    <div className="w-full flex justify-center items-center relative">
      <form
        onSubmit={handleSubmit}
        className="w-full flex justify-center items-center relative"
      >
        {isMobile ? (
          <MobilePromptInput
            prompt={prompt}
            setPrompt={setPrompt}
            placeholderText={placeholderText}
            theme={theme}
            handleSubmit={handleSubmit}
          />
        ) : (
          <DesktopPromptInput
            prompt={prompt}
            placeholderText={placeholderText}
            theme={theme}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
          />
        )}
      </form>
    </div>
  );
}

export default UnifiedPromptInput;
