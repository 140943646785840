import React from "react";
import { FiSend } from "react-icons/fi";
import { useTranslation } from "react-i18next";

interface MobilePromptInputProps {
  prompt: string;
  setPrompt: (prompt: string) => void;
  placeholderText: string;
  theme: "light" | "dark";
  handleSubmit: (e: React.FormEvent) => void;
}

const MobilePromptInput: React.FC<MobilePromptInputProps> = ({
  prompt,
  setPrompt,
  placeholderText,
  theme,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full relative">
      <input
        type="text"
        placeholder={placeholderText}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        className={`w-full px-4 py-3 rounded-full border-2 ${
          theme === "light"
            ? "border-black text-black placeholder-black"
            : "border-white text-white placeholder-white"
        } bg-transparent focus:outline-none focus:ring-2 focus:ring-[#f1f1f4] focus:border-[#f1f1f4] pr-12 transition-all duration-300 ease-out h-12`}
        autoComplete="new-password"
      />
      <button
        type="submit"
        onClick={handleSubmit}
        className={`absolute right-0 top-0 bottom-0 bg-transparent ${
          theme === "light" ? "text-black" : "text-white"
        } rounded-r-full p-3 hover:bg-opacity-10 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-[#f1f1f4]`}
      >
        <FiSend size={24} />
      </button>
    </div>
  );
};

export default MobilePromptInput;
