import CartActions from "./CartActions";

function CartForm() {
  return (
    <form
      className="elem63 hidden box-border flex-col grow shrink basis-[0%] justify-start"
      data-node-type="commerce-cart-form"
      original-class="w-commerce-commercecartform"
    >
      <div
        className="elem65 box-border grow shrink basis-[0%] overflow-auto p-[24px]"
        data-wf-collection="database.commerceOrder.userItems"
        data-wf-template-id="wf-template-12c03a19-b225-186e-a3a2-68862711bd9a"
        original-class="w-commerce-commercecartlist cart-list"
      />
      <div
        className="elem66 box-border [border-top-style:solid] flex-col grow-0 shrink-0 basis-auto flex px-[24px] py-[40px] border-t-[1px] border-t-[#f1f1f4]"
        original-class="w-commerce-commercecartfooter cart-footer"
      >
        <div
          aria-atomic="true"
          aria-live="polite"
          className="elem67 box-border grow-0 shrink-0 basis-auto justify-between items-baseline flex mb-[16px]"
          original-class="w-commerce-commercecartlineitem"
        >
          <div className="elem68 box-border">Subtotal</div>
          <div
            className="elem69 box-border font-bold text-[#050719]"
            data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.subtotal%22%7D%7D%5D"
            original-class="w-commerce-commercecartordervalue color-neutral-800"
          />
        </div>
        <CartActions />
      </div>
    </form>
  );
}

export default CartForm;
