import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { FiArrowLeft } from "react-icons/fi";
import { FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa";
import CapabilitiesAndLimitations from "./CapabilitiesAndLimitations";

interface BetaAnnouncementProps {
  onClose: () => void;
}

const BetaAnnouncement: React.FC<BetaAnnouncementProps> = ({ onClose }) => {
  const announcementRef = useRef<HTMLDivElement>(null);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    if (announcementRef.current) {
      gsap.fromTo(
        announcementRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.5, ease: "power2.out", delay: 0.5 },
      );
    }
  }, []);

  const handleGoBack = () => {
    setIsFadingOut(true);
    if (announcementRef.current) {
      gsap.to(announcementRef.current, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
        onComplete: onClose,
      });
    }
  };

  return (
    <div
      ref={announcementRef}
      className="fixed inset-0 bg-black text-white overflow-y-auto"
    >
      <CapabilitiesAndLimitations onClose={handleGoBack} />
    </div>
  );
};

export default BetaAnnouncement;
