import React from "react";
import { useTheme } from "../../contexts/ThemeContext";

interface GetStartedSectionProps {
  handleGetStarted: () => void;
}

const GetStartedSection: React.FC<GetStartedSectionProps> = ({
  handleGetStarted,
}) => {
  const { theme } = useTheme();

  return (
    <div
      className={`${
        theme === "light" ? "bg-white" : "bg-black"
      } py-8 px-8 rounded-xl mt-12 border ${
        theme === "light" ? "border-gray-200" : "border-gray-800"
      } max-w-2xl mx-auto transition-all duration-300`}
    >
      <h3
        className={`text-2xl font-bold mb-3 ${
          theme === "light" ? "text-black" : "text-white"
        }`}
      >
        Ready to Experience CreateSite.ai?
      </h3>
      <p
        className={`mb-4 ${
          theme === "light" ? "text-gray-600" : "text-gray-300"
        } text-lg`}
      >
        Try our AI-powered website builder and see the possibilities!
      </p>
      <button
        onClick={handleGetStarted}
        className={`w-full ${
          theme === "light"
            ? "bg-black text-white hover:bg-gray-800"
            : "bg-white text-black hover:bg-gray-200"
        } px-8 py-3 rounded-full text-lg font-semibold transition-all duration-300`}
      >
        Get Started
      </button>
    </div>
  );
};

export default GetStartedSection;
