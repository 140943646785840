import { Website } from "./types";

export const unsortedWebsites: Website[] = [
  {
    id: "steelhead1",
    url: "https://createsiteai.b-cdn.net/e85d5cd7-d35c-423b-9195-f6c2d413e502",
    name: "Steelhead",
    description: "Venture firm from San Francisco and Paris.",
    externalUrl: "https://steelhead.vc/",
  },
  {
    id: "tetsuwan",
    url: "https://createsiteai.b-cdn.net/98e3c7c3-8a05-4d38-9e20-b3de8ce765f3",
    name: "Tetsuwan Scientific",
    description: "Mass-manufacturing health wearables for scientific research.",
    externalUrl: "https://tetsuwan.com/",
  },
  {
    id: "altrove",
    url: "https://createsiteai.b-cdn.net/a7014b17-e210-441c-8b41-c7af7062c1e3",
    name: "Altrove",
    description: "Creating the materials of tomorrow, today.",
    externalUrl: "https://altrove.ai/",
  },
  {
    id: "misogi1",
    url: "https://createsiteai.b-cdn.net/0af3a4dc-a830-42e1-9c9d-c57f601f278b",
    name: "Misogi Labs",
    description: "Better molecular optimization, better therapeutics.",
    externalUrl: "https://misogilabs.com/",
  },
];
