import React from "react";
import ListItem from "./ListItem";
import Icon from "../Icon";
import { useTheme } from "../../contexts/ThemeContext";

const Capabilities: React.FC = () => {
  const { theme } = useTheme();

  return (
    <>
      <h2
        className={`text-2xl font-semibold mb-2 ${
          theme === "light" ? "text-black" : "text-white"
        }`}
      >
        Capabilities:
      </h2>
      <ul className="mb-6 space-y-2">
        <ListItem>
          <Icon
            type="check"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>AI-powered stunning design suggestions</span>
        </ListItem>
        <ListItem>
          <Icon
            type="check"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>Intuitive drag-and-drop interface</span>
        </ListItem>
        <ListItem>
          <Icon
            type="check"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>Responsive templates for all devices</span>
        </ListItem>
        <ListItem>
          <Icon
            type="check"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>SEO optimization tools</span>
        </ListItem>
        <ListItem>
          <Icon
            type="check"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>Customizable layouts and color schemes</span>
        </ListItem>
        <ListItem>
          <Icon
            type="check"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>Integration with popular third-party services</span>
        </ListItem>
      </ul>
    </>
  );
};

export default Capabilities;
