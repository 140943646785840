import React from "react";
import { useTheme } from "../contexts/ThemeContext";

const TrustedBy: React.FC = () => {
  const { theme } = useTheme();

  return (
    <div
      className={`w-full flex flex-col justify-center items-center py-8 bg-transparent ${
        theme === "light" ? "text-black" : "text-white"
      }`}
    >
      <p className="text-sm mb-4">Trusted by</p>
      <div className="flex space-x-8 mobile:flex-col mobile:space-x-0 mobile:space-y-4">
        <img
          src="https://createsiteai.b-cdn.net/ef945342-7c59-48f6-91d5-bc551923c977"
          alt="Logo 1"
          className={`h-8 object-contain ${theme === "light" ? "" : "invert"} mobile:mx-auto`}
        />
        <img
          src="https://createsiteai.b-cdn.net/fb1cf9f4-c533-4fc7-ae91-e26c9bd4f530"
          alt="Logo 2"
          className={`h-8 object-contain ${theme === "light" ? "" : "invert"} mobile:mx-auto`}
        />
      </div>
    </div>
  );
};

export default TrustedBy;
