import CartHeader from "./CartHeader";
import CartEmptyState from "./CartEmptyState";
import CartForm from "./CartForm";

function CartContainer() {
  return (
    <div
      className="elem57 box-border w-[100%] min-w-[320px] flex-col flex overflow-auto max-w-[600px] bg-[color:rgba(0,0,0,0)] p-[24px]"
      data-node-type="commerce-cart-container"
      original-class="w-commerce-commercecartcontainer cart-container"
      role="dialog"
    >
      <div
        className="elem58 box-border bg-[color:#f9f9fa] mobile:rounded-br-[12px_12px] mobile:rounded-t-[12px_12px] mobile:rounded-bl-[12px_12px] tablet:rounded-br-[18px_18px] laptop:rounded-br-[18px_18px] desktop:rounded-br-[18px_18px] tablet:rounded-t-[18px_18px] laptop:rounded-t-[18px_18px] desktop:rounded-t-[18px_18px] tablet:rounded-bl-[18px_18px] laptop:rounded-bl-[18px_18px] desktop:rounded-bl-[18px_18px]"
        original-class="card"
      >
        <CartHeader />
        <div
          className="elem62 box-border flex-col grow shrink basis-[0%] flex"
          original-class="w-commerce-commercecartformwrapper"
        >
          <CartForm />
          <CartEmptyState />
          <div
            aria-live="assertive"
            className="elem95 box-border grow-0 shrink-0 basis-auto bg-[color:#ffeff0] text-[#ff5a65] text-center mt-[20px] mb-[24px] mx-[24px] px-[24px] py-[20px] rounded-br-[16px_16px] rounded-t-[16px_16px] rounded-bl-[16px_16px]"
            data-node-type="commerce-cart-error"
            original-class="w-commerce-commercecarterrorstate error-message"
            style={{}}
          >
            <div
              className="elem96 box-border"
              data-w-cart-cart_order_min-error="The order minimum was not met. Add more items to your cart to continue."
              data-w-cart-checkout-error="Checkout is disabled on this site."
              data-w-cart-general-error="Something went wrong when adding this item to the cart."
              data-w-cart-quantity-error="Product is not available in this quantity."
              data-w-cart-subscription_error-error="Before you purchase, please use your email invite to verify your address so we can send order updates."
              original-class="w-cart-error-msg"
            >
              Product is not available in this quantity.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartContainer;
