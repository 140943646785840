import React from "react";
import { useFloatingBetaButton } from "./useFloatingBetaButton";
import ConfettiEffect from "./ConfettiEffect";
import BetaAnnouncement from "../BetaAnnouncement";
import { buttonStyles } from "./styles";

interface FloatingBetaButtonProps {
  onExpand: () => void;
  isVisible: boolean;
}

const FloatingBetaButton: React.FC<FloatingBetaButtonProps> = ({
  onExpand,
  isVisible,
}) => {
  const {
    betaButtonRef,
    betaTextRef,
    confettiRef,
    isExpanded,
    showAnnouncement,
    textOpacity,
    textColor,
    theme,
    handleBetaClick,
    handleCloseAnnouncement,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
  } = useFloatingBetaButton(onExpand);

  if (!isVisible) return null;

  return (
    <>
      <div
        ref={betaButtonRef}
        className={buttonStyles(isExpanded, theme)}
        onClick={handleBetaClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        style={{
          backgroundColor: theme === "light" ? "#000000" : "#ffffff",
        }}
      >
        <span
          ref={betaTextRef}
          style={{ opacity: textOpacity, color: textColor }}
          className="mobile:text-center tablet:text-center w-full block"
        >
          Announcing our public beta 🎉
        </span>
        <ConfettiEffect ref={confettiRef} />
      </div>
      {showAnnouncement && (
        <div className="fixed inset-0 flex items-center justify-center z-[10000]">
          <BetaAnnouncement onClose={handleCloseAnnouncement} />
        </div>
      )}
    </>
  );
};

export default FloatingBetaButton;
