import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";
import { ThemeProvider } from "./contexts/ThemeContext";
import { ToastProvider } from "./contexts/ToastContext";
import { PromptEmailProvider } from "./contexts/PromptEmailContext";
import { VideoProvider } from "./contexts/VideoContext";
import { useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import Home from "./pages/Home";
import EmailCaptureModal from "./components/EmailCaptureModal";
import ContactModal from "./components/ContactModal";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

function App() {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const handleEmailSubmit = (email: string) => {
    console.log("Email submitted:", email);
    setShowEmailModal(false);
  };

  const handleContactSubmit = (email: string, message: string) => {
    console.log("Contact form submitted:", { email, message });
    setShowContactModal(false);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider>
        <ToastProvider>
          <PromptEmailProvider>
            <VideoProvider>
              <Router>
                <div className="relative">
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Home
                          setShowEmailModal={setShowEmailModal}
                          setShowContactModal={setShowContactModal}
                        />
                      }
                    />
                  </Routes>
                </div>
                {showEmailModal && (
                  <EmailCaptureModal
                    onSubmit={handleEmailSubmit}
                    onClose={() => setShowEmailModal(false)}
                  />
                )}
                {showContactModal && (
                  <ContactModal
                    isOpen={showContactModal}
                    onClose={() => setShowContactModal(false)}
                  />
                )}
              </Router>
            </VideoProvider>
          </PromptEmailProvider>
        </ToastProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
