import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";

const HeaderLogo: React.FC = () => {
  const { theme } = useTheme();

  return (
    <div
      className="elem53 box-border flex items-center mobile:mt-8 tablet:mt-8"
      original-class="header-left-side"
    >
      <Link
        aria-current="page"
        className={`elem3 bg-transparent box-border ${
          theme === "light" ? "text-black" : "text-white"
        } no-underline relative mobile:text-xl mobile:mr-4 pl-0 active:outline-none tablet:mr-10 tablet:text-2xl laptop:mr-10 laptop:text-2xl desktop:mr-10 desktop:text-2xl cursor-default`}
        to="#"
        onClick={(e) => e.preventDefault()}
        original-class="header-logo-link left w-nav-brand w--current"
      >
        <span
          className={`text-2xl font-bold ${
            theme === "light" ? "text-black" : "text-white"
          } flex items-center w-full justify-between`}
        >
          <span>CreateSite.ai</span>
        </span>
      </Link>
    </div>
  );
};

export default HeaderLogo;
