function GoogleIcon() {
  return (
    <g className="elem79 box-border" transform="translate(0 4)">
      <mask className="elem80 box-border" fill="#fff" id="google-mark-b">
        <use className="elem81 box-border" xlinkHref="#google-mark-a" />
      </mask>
      <path
        className="elem82 box-border"
        d="M3.4639,5.5337 C3.1369,4.5477 3.1359,3.4727 3.4609,2.4757 L3.4639,2.4777 C3.4679,2.4657 3.4749,2.4547 3.4789,2.4427 L3.4939,0.3287 L0.8939,0.3287 C0.8799,0.3577 0.8599,0.3827 0.8459,0.4117 C-0.2821,2.6667 -0.2821,5.3337 0.8459,7.5887 L0.8459,7.5997 C0.8549,7.6167 0.8659,7.6317 0.8749,7.6487 L3.4939,5.6057 C3.4849,5.5807 3.4729,5.5587 3.4639,5.5337"
        fill="#FBBC04"
        mask="url(#google-mark-b)"
      />
    </g>
  );
}

export default GoogleIcon;
