import { Link } from "react-router-dom";
import CartContainer from "./CartContainer";

function CartWrapper() {
  return (
    <div
      className="elem55 box-border inline-block relative"
      data-node-type="commerce-cart-wrapper"
      data-open-product=""
      data-wf-cart-type="modal"
      data-wf-page-link-href-prefix=""
      original-class="w-commerce-commercecartwrapper cart-button-wrapper"
    >
      <Link
        aria-haspopup="dialog"
        aria-label="Open cart containing  items"
        className="elem4 invisible box-border max-w-[100%] flex cursor-pointer appearance-none items-center no-underline bg-transparent transition-[opacity,color] duration-300 ease-in-out tracking-[0.06em] uppercase text-[14px] font-normal leading-[1.286em] relative text-white p-0 rounded-none border-0 active:outline-none hover:outline-none hover:text-[#3099ff]"
        data-node-type="commerce-cart-open-link"
        data-w-id="12c03a19-b225-186e-a3a2-68862711bd8a"
        to="#"
        original-class="w-commerce-commercecartopenlink cart-button white w-inline-block"
        role="button"
      />
      <div
        className="elem56 hidden box-border z-[1001] bg-[rgba(0,0,0,0.8)] fixed mobile:flex-row justify-center mobile:items-stretch inset-0 tablet:flex-col laptop:flex-col desktop:flex-col tablet:items-center laptop:items-center desktop:items-center"
        data-node-type="commerce-cart-container-wrapper"
        original-class="w-commerce-commercecartcontainerwrapper w-commerce-commercecartcontainerwrapper--cartType-modal cart-wrapper"
      >
        <CartContainer />
      </div>
    </div>
  );
}

export default CartWrapper;
