import { useState, useEffect } from "react";

const useTypewriterEffect = (
  phrases: string[],
  typingSpeed: number = 50,
  pauseBetweenPhrases: number = 1500,
) => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const type = () => {
      const currentPhrase = phrases[currentPhraseIndex];

      if (isDeleting) {
        setCurrentText(currentPhrase.substring(0, currentText.length - 1));
      } else {
        setCurrentText(currentPhrase.substring(0, currentText.length + 1));
      }

      if (!isDeleting && currentText === currentPhrase) {
        timer = setTimeout(() => setIsDeleting(true), pauseBetweenPhrases);
      } else if (isDeleting && currentText === "") {
        setIsDeleting(false);
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
      } else {
        timer = setTimeout(type, typingSpeed);
      }
    };

    timer = setTimeout(type, typingSpeed);

    return () => clearTimeout(timer);
  }, [
    currentText,
    isDeleting,
    currentPhraseIndex,
    phrases,
    typingSpeed,
    pauseBetweenPhrases,
  ]);

  return currentText;
};

export default useTypewriterEffect;
