import React from "react";
import { useTheme } from "../../contexts/ThemeContext";

const HeroDescription: React.FC = () => {
  const { theme } = useTheme();

  return (
    <div
      className="elem104 box-border mobile:max-w-[100%] tablet:max-w-[100%] laptop:max-w-[574px] desktop:max-w-[574px] mx-auto"
      original-class="inner-container _574px _100-tablet"
    >
      <p
        className={`elem105 box-border ${
          theme === "light" ? "text-gray-700" : "text-[#f1f1f4]"
        } mt-[0] mobile:mb-[32px] tablet:mb-[40px] laptop:mb-[40px] desktop:mb-[40px]`}
        original-class="color-neutral-300 mg-bottom-40px"
      >
        Create stunning websites effortlessly with AI-powered design and content
        generation.
      </p>
    </div>
  );
};

export default HeroDescription;
