export const steps = [
  {
    title: "FourSteps.step1.title",
    description: "FourSteps.step1.description",
    media: {
      type: "iframe" as const,
      src: "https://vz-9b5abe92-eb3.b-cdn.net/df7e6e37-a8e0-4bdc-a280-40a5d87a0625/playlist.m3u8",
    },
  },
  {
    title: "FourSteps.step2.title",
    description: "FourSteps.step2.description",
    media: {
      type: "iframe" as const,
      src: "https://vz-9b5abe92-eb3.b-cdn.net/b646a098-f6be-4f41-b74a-817e3a190176/playlist.m3u8",
      placeholder:
        "https://createsiteai.b-cdn.net/c/_/941da797-6153-4e07-bd8e-37f112c2c5c1",
    },
  },
  {
    title: "FourSteps.step3.title",
    description: "FourSteps.step3.description",
    media: {
      type: "iframe" as const,
      src: "https://vz-9b5abe92-eb3.b-cdn.net/2dd60a82-83f0-4dc2-9c0e-1d47284ff3fc/playlist.m3u8",
      placeholder:
        "https://createsiteai.b-cdn.net/c/_/deb7a6dc-b5ef-45b4-b71c-a54881dc76db",
    },
  },
  {
    title: "FourSteps.step4.title",
    description: "FourSteps.step4.description",
    media: {
      type: "iframe" as const,
      src: "https://vz-9b5abe92-eb3.b-cdn.net/7ff02382-5c88-4698-95bd-f5c282feaae1/playlist.m3u8",
      placeholder:
        "https://createsiteai.b-cdn.net/c/_/25139576-5d9c-4e15-9fa3-ab567f671dc9",
    },
  },
];
