import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import EmailSubscription from "./EmailSubscription";
import Capabilities from "./Capabilities";
import Limitations from "./Limitations";
import GetStartedSection from "./GetStartedSection";

interface CapabilitiesAndLimitationsProps {
  onClose: () => void;
}

const CapabilitiesAndLimitations: React.FC<CapabilitiesAndLimitationsProps> = ({
  onClose,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { theme } = useTheme();

  useEffect(() => {
    if (contentRef.current) {
      gsap.fromTo(
        contentRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.5, ease: "power2.out", delay: 0.5 },
      );
    }
  }, []);

  const handleGoBack = () => {
    if (contentRef.current) {
      gsap.to(contentRef.current, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
        onComplete: onClose,
      });
    }
  };

  const handleGetStarted = () => {
    if (contentRef.current) {
      gsap.to(contentRef.current, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => {
          onClose();
          setTimeout(() => {
            navigate("/?autofocus=true");
          }, 500);
        },
      });
    }
  };

  return (
    <div
      className={`fixed inset-0 ${
        theme === "light" ? "bg-white text-black" : "bg-black text-white"
      } overflow-y-auto`}
    >
      <div ref={contentRef} className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <button
            onClick={handleGoBack}
            className={`flex items-center justify-center p-2 border ${
              theme === "light" ? "border-black" : "border-white"
            } rounded-full ${
              theme === "light"
                ? "text-black hover:bg-black hover:text-white"
                : "text-white hover:bg-white hover:text-black"
            } bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#d2d3db] transition duration-300`}
          >
            <FiArrowLeft className="w-5 h-5" />
          </button>
          <EmailSubscription />
        </div>

        <article
          className={`${
            theme === "light" ? "bg-white" : "bg-black"
          } rounded-lg overflow-hidden`}
        >
          <div className="p-8 pt-16">
            <p
              className={`text-base mobile:text-lg tablet:text-xl laptop:text-xl desktop:text-2xl ${
                theme === "light" ? "text-[#525566]" : "text-[#f1f1f4]"
              } mb-4 text-center`}
            >
              September 30, 2024
            </p>
            <h1
              className={`text-4xl md:text-5xl lg:text-6xl mobile:text-4xl tablet:text-5xl laptop:text-6xl desktop:text-7xl font-bold mb-2 mobile:mb-4 ${
                theme === "light" ? "text-black" : "text-white"
              } text-center mobile:whitespace-normal`}
            >
              Introducing CreateSite.ai
            </h1>
            <p
              className={`text-base mobile:text-lg tablet:text-xl laptop:text-xl desktop:text-2xl ${
                theme === "light" ? "text-[#525566]" : "text-[#f1f1f4]"
              } mb-4 text-center mt-6 mobile:mt-4`}
            >
              The easiest way to create a stunning website.
            </p>

            <div
              className={`prose ${
                theme === "light" ? "prose-black" : "prose-invert"
              } max-w-2xl mx-auto`}
            >
              <div className="h-12"></div>
              <p
                className={`text-lg ${
                  theme === "light" ? "text-[#525566]" : "text-[#f1f1f4]"
                } mb-8`}
              >
                CreateSite.ai is revolutionizing the way websites are built. Our
                AI-powered platform combines cutting-edge technology with
                user-friendly design to make website creation accessible to
                everyone. Here's what sets us apart:
              </p>
              <Capabilities />
              <Limitations />
            </div>
          </div>
        </article>

        <GetStartedSection handleGetStarted={handleGetStarted} />
      </div>
    </div>
  );
};

export default CapabilitiesAndLimitations;
