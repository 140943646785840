import { useRef, useState, useEffect } from "react";
import { gsap } from "gsap";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { useTheme } from "../../contexts/ThemeContext";
import {
  animateButton,
  animateText,
  createConfetti,
  getRandomColor,
} from "./styles";

export const useFloatingBetaButton = (onExpand: () => void) => {
  const betaButtonRef = useRef<HTMLDivElement>(null);
  const betaTextRef = useRef<HTMLSpanElement>(null);
  const confettiRef = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline | null>(null);

  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const [isExpanded, setIsExpanded] = useState(false);
  const [textOpacity, setTextOpacity] = useState(1);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [confettiInterval, setConfettiInterval] =
    useState<NodeJS.Timeout | null>(null);

  const scrollPosition = useScrollPosition();
  const { theme } = useTheme();
  const [textColor, setTextColor] = useState(
    theme === "light" ? "#ffffff" : "#000000",
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (betaButtonRef.current) {
        const rect = betaButtonRef.current.getBoundingClientRect();
        setInitialPosition({ x: rect.left, y: rect.top });
      }
    });

    if (betaButtonRef.current) {
      resizeObserver.observe(betaButtonRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (betaButtonRef.current) {
      const rect = betaButtonRef.current.getBoundingClientRect();
      setInitialPosition({ x: rect.left, y: rect.top });
    }

    gsap.from(betaButtonRef.current, {
      y: 50,
      opacity: 0,
      duration: 0.5,
      ease: "power2.out",
    });
  }, []);

  useEffect(() => {
    if (!isExpanded && betaButtonRef.current) {
      const opacity = Math.max(0, 1 - scrollPosition / window.innerHeight);
      const yTranslation = scrollPosition;

      gsap.to(betaButtonRef.current, {
        opacity: opacity,
        y: -yTranslation,
        duration: 0,
      });
    }
  }, [scrollPosition, isExpanded]);

  useEffect(() => {
    animateButton(betaButtonRef, theme);
    animateText(betaTextRef, theme, setTextColor);
  }, [theme]);

  const handleBetaClick = () => {
    if (isExpanded || !betaButtonRef.current) return;
    setIsExpanded(true);
    onExpand();
    animateButtonExpansion(
      betaButtonRef.current,
      betaTextRef.current,
      initialPosition,
      theme,
      setTextOpacity,
      setShowAnnouncement,
    );
  };

  const handleCloseAnnouncement = () => {
    setShowAnnouncement(false);
    if (tl.current && betaButtonRef.current && betaTextRef.current) {
      reverseAnimation(
        tl.current,
        betaButtonRef.current,
        betaTextRef.current,
        theme,
        setIsExpanded,
        setTextOpacity,
      );
    }
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsHovering(true);
    if (!isExpanded) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      createConfetti(x, y, true, confettiRef);

      const interval = setInterval(() => {
        if (confettiRef.current) {
          const x = Math.random() * confettiRef.current.clientWidth;
          const y = Math.random() * confettiRef.current.clientHeight;
          createConfetti(x, y, false, confettiRef);
        }
      }, 50);

      setConfettiInterval(interval);
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    if (confettiInterval) {
      clearInterval(confettiInterval);
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isHovering && !isExpanded) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      createConfetti(x, y, false, confettiRef);
    }
  };

  return {
    betaButtonRef,
    betaTextRef,
    confettiRef,
    isExpanded,
    showAnnouncement,
    textOpacity,
    textColor,
    theme,
    handleBetaClick,
    handleCloseAnnouncement,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
  };
};

function animateButtonExpansion(
  betaButton: HTMLDivElement,
  betaText: HTMLSpanElement | null,
  initialPosition: { x: number; y: number },
  theme: string,
  setTextOpacity: (opacity: number) => void,
  setShowAnnouncement: (show: boolean) => void,
) {
  const buttonRect = betaButton.getBoundingClientRect();
  const centerX = window.innerWidth / 2;
  const centerY = window.innerHeight / 2;
  const moveX = centerX - (initialPosition.x + buttonRect.width / 2);
  const moveY = centerY - (initialPosition.y + buttonRect.height / 2);

  const tl = gsap.timeline();

  gsap.to(betaText, {
    opacity: 0,
    duration: 0.15,
    ease: "power2.out",
    onComplete: () => setTextOpacity(0),
  });

  const scaleX = window.innerWidth / buttonRect.width;
  const scaleY = window.innerHeight / buttonRect.height;
  const scale = Math.max(scaleX, scaleY);

  tl.to(betaButton, {
    paddingTop: "40px",
    duration: 0.15,
    ease: "power2.inOut",
  });

  tl.to(
    betaButton,
    {
      duration: 0.75,
      scale: scale,
      x: moveX,
      y: moveY,
      ease: "power2.inOut",
      transformOrigin: "center center",
    },
    "-=0.15",
  );

  tl.to(
    betaButton,
    {
      backgroundColor: "#000000",
      duration: 0.125,
      ease: "power2.inOut",
    },
    "-=0.25",
  );

  tl.to(
    betaText,
    {
      duration: 0.125,
      opacity: 0,
      ease: "power2.inOut",
    },
    "-=0.25",
  );

  tl.add(() => setShowAnnouncement(true));

  gsap.to("body", {
    duration: 0,
    overflow: "hidden",
  });

  return tl;
}

function reverseAnimation(
  tl: gsap.core.Timeline,
  betaButton: HTMLDivElement,
  betaText: HTMLSpanElement,
  theme: string,
  setIsExpanded: (expanded: boolean) => void,
  setTextOpacity: (opacity: number) => void,
) {
  tl.reverse();

  tl.eventCallback("onReverseComplete", () => {
    setIsExpanded(false);

    gsap.set(betaButton, {
      scale: 1,
      x: 0,
      y: 0,
      left: "50%",
      xPercent: -50,
      backgroundColor: theme === "light" ? "#000000" : "#ffffff",
      paddingTop: "8px",
    });

    gsap.set(betaText, { opacity: 1 });
    setTextOpacity(1);

    gsap.to("body", {
      duration: 0,
      overflow: "auto",
    });
  });
}
