import { Link } from "react-router-dom";
import GooglePayButton from "./GooglePayButton";

function CartActions() {
  return (
    <div className="elem70 box-border">
      <div
        className="elem71 hidden box-border"
        data-node-type="commerce-cart-quick-checkout-actions"
      >
        <a
          aria-haspopup="dialog"
          aria-label="Apple Pay"
          className="elem6 bg-[size:100%_50%] bg-[position:50%_50%] bg-no-repeat box-border bg-[color:#000] text-white cursor-pointer appearance-none min-h-[30px] items-center no-underline flex transition-[color] duration-[0.3s] ease-[ease] delay-0 h-[54px] mb-[8px] p-[0] rounded-br-[90px_90px] rounded-t-[90px_90px] rounded-bl-[90px_90px] border-[0] active:outline-0 active:outline-none active:outline-[currentcolor] hover:outline-0 hover:outline-none hover:outline-[currentcolor] hover:text-[#050719]"
          data-node-type="commerce-cart-apple-pay-button"
          original-class="w-commerce-commercecartapplepaybutton apple-pay-btn cart-btn"
          role="button"
          tabIndex={0}
        >
          <div className="elem72 box-border" />
        </a>
        <GooglePayButton />
      </div>
      <Link
        className="elem8 box-border cursor-pointer appearance-none items-center no-underline block bg-[color:rgba(0,0,0,0)] text-[#050719] text-center transition-[border-color,transform,background-color,color] duration-[0.3s,0.3s,0.3s,0.3s] ease-[ease,ease,ease,ease] delay-[0s,0s,0s,0s] [border-top-style:solid] [border-right-style:solid] [border-bottom-style:solid] [border-left-style:solid] tracking-[0.06em] uppercase font-normal leading-[1.143em] text-[length:14px] mobile:w-[100%] px-[24px] mobile:py-[16px] rounded-br-[12px_12px] rounded-t-[12px_12px] rounded-bl-[12px_12px] border-[1px] border-[#050719] active:outline-0 active:outline-none active:outline-[currentcolor] hover:outline-0 hover:outline-none hover:outline-[currentcolor] hover:text-[white] hover:bg-[color:#050719] hover:border-[#050719] hover:scale-[0.98] tablet:py-[18px] laptop:py-[18px] desktop:py-[18px]"
        data-loading-text="Hang Tight..."
        data-node-type="cart-checkout-button"
        to="#"
        original-class="w-commerce-commercecartcheckoutbutton btn-secondary small"
        value="Continue to Checkout"
      >
        Continue to Checkout
      </Link>
    </div>
  );
}

export default CartActions;
