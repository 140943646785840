import React from "react";
import ListItem from "./ListItem";
import Icon from "../Icon";
import { useTheme } from "../../contexts/ThemeContext";

const Limitations: React.FC = () => {
  const { theme } = useTheme();

  return (
    <>
      <h2
        className={`text-2xl font-semibold mb-2 ${
          theme === "light" ? "text-black" : "text-white"
        }`}
      >
        Limitations:
      </h2>
      <ul className="mb-12 space-y-2">
        <ListItem>
          <Icon
            type="x"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>Limited advanced customization options for developers</span>
        </ListItem>
        <ListItem>
          <Icon
            type="x"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>Restricted to pre-defined AI-generated templates</span>
        </ListItem>
        <ListItem>
          <Icon
            type="x"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>
            May not be suitable for highly complex, custom-coded websites
          </span>
        </ListItem>
        <ListItem>
          <Icon
            type="x"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>Limited control over server-side functionality</span>
        </ListItem>
        <ListItem>
          <Icon
            type="x"
            className={`mr-2 mt-1 ${
              theme === "light" ? "text-black" : "text-white"
            }`}
          />
          <span>
            Dependency on CreateSite.ai's platform for hosting and updates
          </span>
        </ListItem>
      </ul>
    </>
  );
};

export default Limitations;
