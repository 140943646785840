import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";

const HeroHeading: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <h1
      className={`elem13 box-border font-normal leading-[1.175em] ${
        theme === "light" ? "text-black" : "text-white"
      } mobile:text-[length:42px] mt-[0] mobile:mb-[12px] mx-[0] tablet:text-[length:72px] tablet:mb-[16px] laptop:mb-[16px] desktop:mb-[16px] laptop:text-[length:80px] desktop:text-[length:80px]`}
      original-class="display-1 color-neutral-100"
    >
      {t("UnifiedPromptInput.tagline")}
    </h1>
  );
};

export default HeroHeading;
