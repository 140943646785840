import { Link } from "react-router-dom";

function CartEmptyState() {
  return (
    <div
      className="elem92 box-border grow shrink basis-[0%] justify-center items-center flex flex-col py-[100px]"
      original-class="w-commerce-commercecartemptystate flex-vertical"
    >
      <div
        aria-label="This cart is empty"
        aria-live="polite"
        className="elem93 box-border text-[#050719] font-normal leading-[1.333em] mobile:text-[length:16px] mobile:mb-[12px] tablet:text-[length:18px] laptop:text-[length:18px] desktop:text-[length:18px] tablet:mb-[16px] laptop:mb-[16px] desktop:mb-[16px]"
        original-class="heading-h5-size mg-bottom-16px"
      >
        No items found.
      </div>
      <div className="elem94 box-border">
        <Link
          className="elem9 box-border cursor-pointer inline-block bg-[color:#050719] text-[white] leading-[1.111em] [border-top-style:solid] [border-right-style:solid] [border-bottom-style:solid] [border-left-style:solid] no-underline transition-[border-color,background-color,transform,color] duration-[0.3s,0.3s,0.3s,0.3s] ease-[ease,ease,ease,ease] delay-[0s,0s,0s,0s] text-center tracking-[0.06em] uppercase justify-center font-normal mobile:w-[100%] mobile:px-[28px] mobile:py-[18px] rounded-br-[12px_12px] rounded-t-[12px_12px] rounded-bl-[12px_12px] border-[1px] border-[#050719] active:outline-0 active:outline-none active:outline-[currentcolor] hover:outline-0 hover:outline-none hover:outline-[currentcolor] hover:text-[#050719] hover:bg-[color:rgba(0,0,0,0)] hover:scale-[0.96] tablet:px-[32px] laptop:px-[32px] desktop:px-[32px] tablet:py-[22px] laptop:py-[22px] desktop:py-[22px]"
          to="#"
          original-class="btn-primary w-button"
        >
          Go to pricing
        </Link>
      </div>
    </div>
  );
}

export default CartEmptyState;
