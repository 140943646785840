import React from "react";
import { WebsiteCarouselProps } from "./types";
import { unsortedWebsites } from "./websiteData";
import WebsiteCard from "./WebsiteCard";
import { useTheme } from "../../contexts/ThemeContext";

const WebsiteCarousel: React.FC<WebsiteCarouselProps> = ({ websiteOrder }) => {
  const websites = unsortedWebsites.sort(
    (a, b) => websiteOrder.indexOf(a.id) - websiteOrder.indexOf(b.id),
  );

  const { theme } = useTheme();

  return (
    <div
      className={`pt-32 pb-20 ${theme === "light" ? "bg-white" : "bg-black"} hidden`}
    >
      <h2
        className={`text-4xl md:text-6xl lg:text-7xl mobile:text-3xl tablet:text-6xl laptop:text-7xl desktop:text-8xl font-bold text-center ${theme === "light" ? "text-black" : "text-white"} mb-12`}
      >
        They chose CreateSite
      </h2>
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {websites.map((website) => (
            <WebsiteCard key={website.id} website={website} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WebsiteCarousel;
