import React from "react";
import { FiCheck, FiX } from "react-icons/fi";
import { useTheme } from "../contexts/ThemeContext";

interface IconProps {
  type: "check" | "x";
  className?: string;
}

const Icon: React.FC<IconProps> = ({ type, className = "" }) => {
  const { theme } = useTheme();
  const IconComponent = type === "check" ? FiCheck : FiX;
  return (
    <div className={`flex-shrink-0 w-6 h-6 ${className}`}>
      <IconComponent
        className={`w-full h-full ${theme === "light" ? "text-black" : "text-white"}`}
      />
    </div>
  );
};

export default Icon;
