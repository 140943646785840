import React, { useRef, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import { Points, PointMaterial } from "@react-three/drei";
import * as THREE from "three";
import { useScrollPosition } from "../../hooks/useScrollPosition";

interface ParticleSystemProps {
  opacity: number;
  color: string;
}

const ParticleSystem: React.FC<ParticleSystemProps> = ({ opacity, color }) => {
  const ref = useRef<THREE.Points>(null);
  const particleCount = 4000;
  const scrollPosition = useScrollPosition();
  const maxScrollDepth = 1000; // Adjust this value based on your page height

  const positions = useMemo(() => {
    const positions = new Float32Array(particleCount * 3);
    for (let i = 0; i < particleCount; i++) {
      positions[i * 3] = (Math.random() - 0.5) * 10;
      positions[i * 3 + 1] = (Math.random() - 0.5) * 10;
      positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
    }
    return positions;
  }, [particleCount]);

  useFrame((state) => {
    if (ref.current) {
      const time = state.clock.getElapsedTime();
      const scrollFactor = Math.min(scrollPosition / maxScrollDepth, 1);

      // Subtle 2D movement
      ref.current.position.x = Math.sin(time * 0.3) * 0.2;
      ref.current.position.y = Math.cos(time * 0.3) * 0.2;

      // 3D rotation based on scroll
      ref.current.rotation.x = scrollFactor * Math.PI * 0.5;
      ref.current.rotation.y = scrollFactor * Math.PI * 0.5;
    }
  });

  return (
    <Points ref={ref} positions={positions} stride={3}>
      <PointMaterial
        transparent
        color={color}
        size={0.009}
        sizeAttenuation={true}
        maxSize={0.045}
        depthWrite={false}
        opacity={opacity}
      />
    </Points>
  );
};

export default ParticleSystem;
