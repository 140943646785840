import React, { useRef, useEffect, useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import Step from "./Step";
import { steps } from "./stepsData";

const FourSteps = () => {
  const lineRef = useRef(null);
  const containerRef = useRef(null);
  const [visibleSteps, setVisibleSteps] = useState([]);
  const stepRefs = useRef([]);
  const { theme } = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    const line = lineRef.current;
    const container = containerRef.current;

    if (!line || !container) return;

    const updateLineHeight = () => {
      const containerRect = container.getBoundingClientRect();
      const containerTop = containerRect.top;
      const containerHeight = containerRect.height;
      const viewportHeight = window.innerHeight;

      let progress =
        (viewportHeight - containerTop) / (containerHeight + viewportHeight);
      progress = Math.max(0, Math.min(1, progress));

      line.style.height = `${progress * 100}%`;

      // Calculate which steps should be visible
      const newVisibleSteps = stepRefs.current.map((stepRef, index) => {
        if (!stepRef) return false;
        const stepRect = stepRef.getBoundingClientRect();
        const stepTop = stepRect.top - containerTop;
        const lineHeight = line.offsetHeight;
        return lineHeight >= stepTop;
      });

      setVisibleSteps(newVisibleSteps);
    };

    const scrollHandler = () => {
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(updateLineHeight);
      });
    };

    window.addEventListener("scroll", scrollHandler);
    updateLineHeight(); // Initial update

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [steps.length]);

  return (
    <div
      className={`py-20 ${theme === "light" ? "bg-white" : "bg-black"} ${theme === "light" ? "text-black" : "text-white"}`}
    >
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="text-4xl md:text-6xl lg:text-7xl mobile:text-3xl tablet:text-6xl laptop:text-7xl desktop:text-8xl font-bold text-center mb-16">
          {t("FourSteps.title")}
        </h2>
{/* <iframe src="https://player.vimeo.com/video/1016422063?autoplay=1&muted=1&title=0&byline=0&portrait=0&transparent=0&dnt=1&sidedock=0&controls=0&loop=1" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
        <div ref={containerRef} className="relative">
          <div
            ref={lineRef}
            className={`absolute left-6 top-0 w-0.5 ${theme === "light" ? "bg-black" : "bg-white"} z-0`}
            style={{ height: "0%", transition: "height 0.01s linear" }}
          ></div>
          <div
            className={`absolute left-6 top-0 bottom-0 w-0.5 ${theme === "light" ? "bg-black" : "bg-white"} opacity-10 z-0`}
          ></div>
          {steps.map((step, index) => (
            <div key={index} ref={(el) => (stepRefs.current[index] = el)}>
              <Step
                {...step}
                number={index + 1}
                isVisible={visibleSteps[index]}
                isFirstStep={index === 0}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FourSteps;
