import { useState, useEffect, RefObject } from "react";
import { gsap } from "gsap";

export const useHandAnimation = (
  inputRef: RefObject<HTMLInputElement>,
  inputContainerRef: RefObject<HTMLDivElement>,
  leftHandRef: RefObject<SVGSVGElement>,
  rightHandRef: RefObject<SVGSVGElement>,
) => {
  const [showHands, setShowHands] = useState(false);

  useEffect(() => {
    const handleFocus = () => {
      setShowHands(true);
      if (leftHandRef.current && rightHandRef.current) {
        gsap.to(leftHandRef.current, {
          x: "10%",
          duration: 1,
          repeat: -1,
          yoyo: true,
          ease: "power1.inOut",
          transformOrigin: "center",
        });

        gsap.to(rightHandRef.current, {
          x: "-10%",
          duration: 1,
          repeat: -1,
          yoyo: true,
          ease: "power1.inOut",
          transformOrigin: "center",
        });
      }

      if (inputContainerRef.current) {
        gsap.to(inputContainerRef.current, {
          width: "90%",
          duration: 0.5,
          ease: "power2.out",
        });
      }
    };

    const handleBlur = () => {
      if (leftHandRef.current && rightHandRef.current) {
        gsap.to([leftHandRef.current, rightHandRef.current], {
          x: 0,
          duration: 0.5,
          ease: "power1.inOut",
        });
        gsap.killTweensOf(leftHandRef.current);
        gsap.killTweensOf(rightHandRef.current);
        gsap.set([leftHandRef.current, rightHandRef.current], { x: 0 });
      }

      if (inputContainerRef.current) {
        gsap.to(inputContainerRef.current, {
          width: "75%",
          duration: 0.5,
          ease: "power2.out",
        });
      }
    };

    const input = inputRef.current;
    if (input) {
      input.addEventListener("focus", handleFocus);
      input.addEventListener("blur", handleBlur);
    }

    return () => {
      if (input) {
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("blur", handleBlur);
      }
    };
  }, [inputRef, inputContainerRef, leftHandRef, rightHandRef]);

  return { showHands };
};
