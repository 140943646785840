import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePromptEmail } from "../../contexts/PromptEmailContext";
import useTypewriterEffect from "../../hooks/useTypewriterEffect";
import { useFormspark } from "@formspark/use-formspark";
import { useTranslation } from "react-i18next";

export const useUnifiedPromptInput = (
  setShowEmailModal: (show: boolean) => void,
) => {
  const { prompt, setPrompt, autoFocus, setAutoFocus } = usePromptEmail();
  const location = useLocation();
  const { t } = useTranslation();
  const [submit, submitting] = useFormspark({
    formId: "WecYjXrbe",
  });

  const startupDescriptions = [
    t("UnifiedPromptInput.placeholder1"),
    t("UnifiedPromptInput.placeholder2"),
    t("UnifiedPromptInput.placeholder3"),
    t("UnifiedPromptInput.placeholder1"),
    t("UnifiedPromptInput.placeholder2"),
  ];

  const placeholderText = useTypewriterEffect(startupDescriptions, 30, 1500);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const shouldAutofocus = searchParams.get("autofocus") === "true";

    if (shouldAutofocus) {
      setTimeout(() => {
        setAutoFocus(true);
        searchParams.delete("autofocus");
        window.history.replaceState(
          {},
          "",
          `${location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ""}`,
        );
      }, 500);
    }
  }, [location, setAutoFocus]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (prompt.trim() !== "") {
      setShowEmailModal(true);
      try {
        await submit({ prompt: prompt.trim() });
        console.log("Prompt submitted to Formspark:", prompt.trim());
      } catch (error) {
        console.error("Error submitting prompt to Formspark:", error);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
  };

  return {
    placeholderText,
    handleSubmit,
    handleInputChange,
  };
};
