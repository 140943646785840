import React, { useState } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTheme } from "../../contexts/ThemeContext";
import Header from "../../components/Header";
import HeroSection from "../../components/HeroSection";
import WebsiteCarousel from "../../components/WebsiteCarousel";
import MobileWebsiteList from "../../components/MobileWebsiteList";
import GrowingImage from "../../components/GrowingImage";
import FullScreenSection from "../../components/FullScreenSection";
import FloatingBetaButton from "../../components/FloatingBetaButton";
import Footer from "../../components/Footer";
import FourSteps from "../../components/FourSteps";
import MobileFourSteps from "../../components/MobileFourSteps";
import { useHomeAnimations } from "./useHomeAnimations";
import { websiteOrder } from "./websiteOrder";

interface HomeProps {
  setShowEmailModal: (show: boolean) => void;
  setShowContactModal: (show: boolean) => void;
}

function Home({ setShowEmailModal, setShowContactModal }: HomeProps) {
  useHomeAnimations();
  const [isBetaExpanded, setIsBetaExpanded] = useState(false);
  const [isBetaButtonVisible, setBetaButtonVisible] = useState(true);
  const { theme } = useTheme();

  const handleBetaExpand = () => {
    setIsBetaExpanded(true);
  };

  return (
    <div
      className={`elem49 box-border relative ${theme === "light" ? "bg-white text-black" : "bg-black text-white"}`}
      original-class="page-wrapper"
    >
      <FloatingBetaButton
        onExpand={handleBetaExpand}
        isVisible={isBetaButtonVisible}
      />
      <div
        className={`elem50 box-border z-[999] bg-[image:none] bg-repeat bg-[position:left_top] bg-[size:auto] bg-scroll bg-origin-padding bg-clip-border ${
          theme === "light" ? "bg-white" : "bg-[color:rgba(0,0,0,0)]"
        } absolute mobile:py-[24px] tablet:py-[24px] top-[0%] bottom-auto inset-x-[0%] before:content-['_'] before:table after:content-['_'] after:table after:clear-both laptop:py-[32px] desktop:py-[32px]`}
        data-animation="default"
        data-collapse="medium"
        data-duration={400}
        data-easing="ease"
        data-easing2="ease"
        data-w-id="d507ab60-f113-b22c-b32d-66ec128d2ca5"
        original-class="header-wrapper w-nav"
        role="banner"
        style={{}}
      >
        <Header
          setBetaButtonVisible={setBetaButtonVisible}
          setShowContactModal={setShowContactModal}
        />
        <div
          className="elem99 box-border w-[100%] hidden absolute overflow-hidden top-full inset-x-[0]"
          data-wf-ignore=""
          id="w-nav-overlay-0"
          original-class="w-nav-overlay"
        />
      </div>
      <HeroSection setShowEmailModal={setShowEmailModal} />{" "}
      {useMediaQuery("(max-width: 767px)") ? (
        <MobileFourSteps />
      ) : (
        <FourSteps />
      )}
      {useMediaQuery("(max-width: 767px)") ? (
        <MobileWebsiteList websiteOrder={websiteOrder} />
      ) : (
        <WebsiteCarousel websiteOrder={websiteOrder} />
      )}
      <FullScreenSection>
        <GrowingImage />
      </FullScreenSection>
      <Footer />
    </div>
  );
}

export default Home;
