import React from "react";
import { useTheme } from "../contexts/ThemeContext";

const Footer: React.FC = () => {
  const { theme } = useTheme();

  return (
    <footer
      className={`py-8 ${theme === "light" ? "bg-white text-black" : "bg-black text-white"} text-sm text-center`}
    >
      <p className="mt-4">Made with CreateSite.ai</p>
    </footer>
  );
};

export default Footer;
