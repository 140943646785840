import React, { useState } from "react";
import { useFormspark } from "@formspark/use-formspark";
import { useTheme } from "../../contexts/ThemeContext";

const EmailSubscription: React.FC = () => {
  const [submit, submitting] = useFormspark({
    formId: "NwZLPYs0f",
  });

  const [email, setEmail] = useState("");
  const [buttonText, setButtonText] = useState("Get Updates");
  const { theme } = useTheme();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (email && /\S+@\S+\.\S+/.test(email)) {
      await submit({ email });
      setEmail("");
      setButtonText("You're in!");
    }
  };

  return (
    <div className="relative flex items-center w-full max-w-md overflow-hidden mobile:pl-4">
      <form onSubmit={handleSubmit} className="w-full">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            if (e.target.value && buttonText !== "Get Updates") {
              setButtonText("Get Updates");
            }
          }}
          className={`w-full px-3 pr-32 py-2 border-b ${
            theme === "light" ? "border-black" : "border-white"
          } bg-transparent ${
            theme === "light" ? "text-black" : "text-white"
          } placeholder-gray-400 focus:outline-none focus:ring-0 transition-all duration-300 mobile:pl-0`}
        />
        <button
          type="submit"
          disabled={submitting}
          className={`absolute right-0 px-4 py-2 bg-transparent ${
            theme === "light"
              ? "text-black hover:text-gray-600"
              : "text-white hover:text-gray-300"
          } transition duration-300 focus:outline-none focus:ring-0`}
        >
          {submitting ? "Sending..." : buttonText}
        </button>
      </form>
    </div>
  );
};

export default EmailSubscription;
