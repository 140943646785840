import GoogleIcon from "./GoogleIcon";

function GooglePayButton() {
  return (
    <a
      aria-haspopup="dialog"
      className="elem7 hidden box-border bg-[color:#000] text-white cursor-pointer appearance-none h-[38px] justify-center items-center no-underline transition-[color] duration-[0.3s] ease-[ease] delay-0 mb-[8px] px-[15px] py-[0] rounded-br-[2px_2px] rounded-t-[2px_2px] rounded-bl-[2px_2px] border-[0] active:outline-0 active:outline-none active:outline-[currentcolor] hover:outline-0 hover:outline-none hover:outline-[currentcolor] hover:text-[#050719]"
      data-node-type="commerce-cart-quick-checkout-button"
      original-class="w-commerce-commercecartquickcheckoutbutton"
      role="button"
      tabIndex={0}
    >
      <GoogleIcon />
      <svg
        className="elem26 box-border block mr-[8px] overflow-hidden"
        height={16}
        original-class="w-commerce-commercequickcheckoutmicrosofticon"
        viewBox="0 0 16 16"
        width={16}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="elem86 box-border" fill="none" fillRule="evenodd">
          <polygon
            className="elem87 box-border"
            fill="#F05022"
            points="7 7 1 7 1 1 7 1"
          />
          <polygon
            className="elem88 box-border"
            fill="#7DB902"
            points="15 7 9 7 9 1 15 1"
          />
          <polygon
            className="elem89 box-border"
            fill="#00A4EE"
            points="7 15 1 15 1 9 7 9"
          />
          <polygon
            className="elem90 box-border"
            fill="#FFB700"
            points="15 15 9 15 9 9 15 9"
          />
        </g>
      </svg>
      <div className="elem91 box-border">Pay with browser.</div>
    </a>
  );
}

export default GooglePayButton;
