import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import CustomVideoPlayer from "../VideoPlayer/CustomVideoPlayer";

interface StepProps {
  title: string;
  description: string;
  number: number;
  media: {
    type: "iframe";
    src: string;
  };
  isVisible: boolean;
  isFirstStep: boolean;
}

const Step: React.FC<StepProps> = ({
  title,
  description,
  number,
  media,
  isVisible,
  isFirstStep,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={
        isVisible && inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
      }
      transition={{ duration: 0.5 }}
      className={`flex items-start mb-16 last:mb-0 ${isFirstStep ? "" : "mt-12"}`}
    >
      <div className="flex-shrink-0 mr-8 relative z-10">
        <div
          className={`w-12 h-12 ${theme === "light" ? "bg-black text-white" : "bg-white text-black"} rounded-full flex items-center justify-center text-xl font-bold relative z-10 border-4 ${theme === "light" ? "border-white" : "border-black"}`}
        >
          {number}
        </div>
      </div>
      <div className="flex-grow">
        <div className="mb-4">
          <h3
            className={`text-2xl font-bold ${theme === "light" ? "text-black" : "text-white"}`}
          >
            {t(title)}
          </h3>
        </div>
        <p
          className={`${theme === "light" ? "text-gray-700" : "text-[#f1f1f4]"} mb-4`}
        >
          {t(description)}
        </p>
        <div className="overflow-hidden">
          <CustomVideoPlayer url={media.src} />
        </div>
      </div>
    </motion.div>
  );
};

export default Step;
