import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import CustomVideoPlayer from "../VideoPlayer/CustomVideoPlayer";

interface StepProps {
  title: string;
  description: string;
  number: number;
  media: {
    src: string;
  };
}

const Step: React.FC<StepProps> = ({ title, description, number, media }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }}
      className="mb-8 last:mb-0"
    >
      <div className="flex items-center mb-4">
        <div
          className={`w-10 h-10 ${theme === "light" ? "bg-black text-white" : "bg-white text-black"} rounded-full flex items-center justify-center text-xl font-bold mr-4`}
        >
          {number}
        </div>
        <h3
          className={`text-xl font-bold ${theme === "light" ? "text-black" : "text-white"}`}
        >
          {t(title)}
        </h3>
      </div>
      <p
        className={`${theme === "light" ? "text-gray-700" : "text-[#f1f1f4]"} mb-4`}
      >
        {t(description)}
      </p>
      <div className="overflow-hidden">
        <CustomVideoPlayer url={media.src} />
      </div>
    </motion.div>
  );
};

export default Step;
