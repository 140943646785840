import { Link } from "react-router-dom";

function CartHeader() {
  return (
    <div
      className="elem59 box-border [border-bottom-style:solid] grow-0 shrink-0 basis-auto justify-between items-center flex relative px-[24px] py-[22px] border-b-[1px] border-b-[#f1f1f4]"
      original-class="w-commerce-commercecartheader cart-header"
    >
      <h4
        className="elem60 box-border font-normal leading-[1.273em] text-[#050719] mobile:text-[length:20px] tablet:text-[length:20px] my-[0] px-[0] laptop:text-[length:22px] desktop:text-[length:22px]"
        original-class="w-commerce-commercecartheading"
      >
        Your Cart
      </h4>
      <Link
        aria-label="Close cart"
        className="elem5 bg-[color:rgba(0,0,0,0)] box-border max-w-[100%] transition-[color] duration-[0.3s] ease-[ease] delay-0 flex w-[24px] h-[24px] text-[#050719] no-underline justify-center items-center active:outline-0 active:outline-none active:outline-[currentcolor] hover:outline-0 hover:outline-none hover:outline-[currentcolor] hover:text-[#3099ff]"
        data-node-type="commerce-cart-close-link"
        to="#"
        original-class="w-commerce-commercecartcloselink cart-close-button w-inline-block"
        role="button"
      >
        <div
          className="elem61 box-border"
          original-class="line-rounded-icon"
        ></div>
      </Link>
    </div>
  );
}

export default CartHeader;
